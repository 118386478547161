::-webkit-calendar-picker-indicator {
    filter: invert(1) brightness(50%)
}

.MuiNativeSelect-iconStandard {
    color: #888888 !important;
}

/* Hide the filter button */
.MuiButtonBase-root[aria-label="Operator"] {
    display: none;
}

/* Hide the clear filter button */
.MuiButtonBase-root[aria-label="Clear filter"] {
    display: none;
}

/* Change color of nav element on hover */
#navigation p:hover {
    opacity: 0.75;
}

/* Animation for the background of the page */
@keyframes gradientAnimation {
    0%, 100% {
        background-position: 100% 50%;
    }
    50% {
        background-position: 0 50%;
    }
}
